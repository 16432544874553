export const colorPaletteLight = {
  type: "light",
  primary: {
    main: "#484A9E",
  },
  text: {
    primary: "#222222",
  },
  secondary: {
    main: "#ececec",
  },
  error: {
    main: "#B00020",
  },
  // success: {
  //   main: "",
  // },
  background: {
    // default: "#f6f6f6",
    default: "#fff",
  },
};
